import { Protocol } from './index';
import { Category } from '../constants';

const rwa: Protocol[] = [
  {
    name: 'DYLI',
    url: 'https://dyli.io',
    logoPath: '/logos/dyli.jpg',
    category: Category.RWA,
    twitterUrl: 'https://x.com/dyli_io',
  },
];

export { rwa };
